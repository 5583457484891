/* eslint-disable no-await-in-loop */
import timeout, { TimeoutError } from "../../lib/helper/timeout";
import Protocol from "../../types/contracts/Protocol";
import { DeviceInfo, SetParametersPayload } from "../../types/types-bluetooth";
import { MeasurementResult } from "../../types/types-measurement";
import BluetoothService from "./BluetoothService";
import LogService from "../LogService";

const DEFAULT_TIMEOUT_MS = 10000;
const MEASUREMENT_TIMEOUT_MS = 10000;
const MAX_RETRY = 3;

function waitWithExponentialBackOffForRetry(
  attempt: number,
  baseDelayMs = 500
): Promise<void> {
  const NOISE = 500;
  const jitter = Math.floor((Math.random() - 0.5) * NOISE);
  const delayMs = baseDelayMs * 2 ** attempt + jitter;
  return new Promise((resolve) => {
    setTimeout(resolve, delayMs);
  });
}

/**
 * The Received Signal Strength Indicator
 *
 * A more strong connection is between -30 to -55
 * A strong connection starts from -55 to -67
 * A terrible connection starts from -80 to -90
 * An unusable connection starts from -90 and below
 *
 * Source: https://www.mokoblue.com/measures-of-bluetooth-rssi/#:~:text=Bluetooth%20RSSI%20(Received%20Signal%20Strength,device%20scans%20for%20Bluetooth%20devices.
 */
const SIGNAL_STRENGTH_REQUIREMENT = -79;

interface PeripheralCharacteristic {
  service: string;
  characteristic: string;
  properties: string[];
  descriptors?: any[] | undefined;
}

interface PeripheralData {
  name: string;
  id: string;
  rssi: number;
  advertising: ArrayBuffer | any;
}

interface PeripheralDataExtended extends PeripheralData {
  services: string[];
  characteristics: PeripheralCharacteristic[];
}

async function connect(
  address: string,
  timeoutMs: number,
  errorHint: (error: string) => void
): Promise<boolean | void> {
  let retry = 0;
  let connectResult;
  while (retry < MAX_RETRY && !connectResult) {
    retry += 1;
    try {
      // eslint-disable-next-line no-await-in-loop
      const result = await Promise.race([
        BluetoothService.createConnection(address),
        timeout(timeoutMs),
      ]);

      if (result instanceof TimeoutError) {
        if (retry === MAX_RETRY) {
          throw result.message;
        }
        errorHint(
          "Connection could not be established... make sure the device is charged and turned on"
        );
      } else if (result === null) {
        throw new Error("Connection failed or scanner is turned off");
      } else {
        connectResult = result;
        const data = result as Pick<PeripheralDataExtended, "rssi">;

        if (data?.rssi < SIGNAL_STRENGTH_REQUIREMENT) {
          // rssi is below accepted parameter and we want to prevent a connection
          return false;
        }

        LogService.log("Connect successful", connectResult);
      }
    } catch (e) {
      if (e instanceof Error) {
        if (retry === MAX_RETRY) {
          throw new Error(`Connection failed ${e.message}`);
        }
        errorHint(e.message);
        await waitWithExponentialBackOffForRetry(retry);
      }
    }
  }

  return true;
}

async function setParameters(
  address: string,
  timeoutMs: number,
  errorHint: (error: string) => void,
  protocol: Protocol
) {
  let retry = 0;
  let setParametersResult;

  while (retry < MAX_RETRY && !setParametersResult) {
    retry += 1;
    LogService.log(`[BLE] Set Parameter Retry number ${retry}`);

    try {
      const protocolPayload: SetParametersPayload = {
        wavelengthData: {
          mode: 0,
          wavelengths: protocol.wavelengths,
        },
        wavelengthAverage: protocol.wavelengthAverage,
        scanAverage: protocol.scanAverage,
        lightSourceIntensity: protocol.lightSourceIntensity,
        lightSourceAutomaticMode: protocol.lightSourceAutomaticMode,
        // automaticDark and subtractDark either need to be both true or both false
        // based on subtractDark boolean from the backend.
        automaticDark: protocol.subtractDark,
        subtractDark: protocol.subtractDark,
      };

      const setparameterPromise = BluetoothService.setParameters(
        address,
        protocolPayload
      );
      // eslint-disable-next-line no-await-in-loop
      const result = await Promise.race([
        setparameterPromise,
        timeout(timeoutMs),
      ]);
      if (result instanceof TimeoutError) {
        if (retry === MAX_RETRY) {
          throw result;
        }
        errorHint("Connection problem occured");
      } else {
        setParametersResult = result;
        LogService.log("SetParameters successful ", setParametersResult);
      }
    } catch (e) {
      if (e instanceof Error) {
        if (retry === MAX_RETRY) {
          throw new Error(`SetParameters failed ${e.message}`);
        }
        // TODO have different user faced messages for different error sources
        errorHint(`${e.message}`);
        await waitWithExponentialBackOffForRetry(retry);
      }
    }
  }
}

async function measure(
  address: string,
  timeoutMs: number,
  errorHint: (error: string) => void
): Promise<MeasurementResult> {
  let retry = 0;
  let measurementResult;
  while (retry < MAX_RETRY && !measurementResult) {
    retry += 1;
    try {
      // eslint-disable-next-line no-await-in-loop
      const result = await Promise.race([
        BluetoothService.measure(address),
        timeout(timeoutMs),
      ]);
      if (result instanceof TimeoutError) {
        if (retry === MAX_RETRY) {
          throw result;
        }
        errorHint("Connection problem, trying again...");
      } else if (!(result instanceof Error)) {
        measurementResult = result;
      }
    } catch (e) {
      if (e instanceof Error) {
        if (retry === MAX_RETRY) {
          throw new Error(`Measurement failed ${e.message}`);
        }
        errorHint(`${e.message} trying again...`);
        await waitWithExponentialBackOffForRetry(retry);
      }
    }
  }

  if (measurementResult) {
    LogService.log("Got measurementResult", measurementResult);

    return {
      value: measurementResult.values,
      darkValue: [], // Firmware doesnt support darkValues yet
    };
  }
  throw new Error("No MeasurementResult available");
}

async function disconnect(address: string) {
  try {
    await BluetoothService.disconnect(address);
  } catch (e) {
    if (e instanceof Error) {
      // don't fail calibration or measurement because we couldn't disconnect
      LogService.warn(`Disconnect failed ${e.message}`);
    }
  }
}

async function calibrateDevice(
  address: string,
  protocols: Protocol[],
  errorHint: (error: string) => void
): Promise<MeasurementResult[]> {
  const connectResult = await connect(address, DEFAULT_TIMEOUT_MS, errorHint);

  if (!connectResult) {
    await disconnect(address);
    throw new Error("Signal strength is insuffient to support calibration.");
  }

  const results = new Array<MeasurementResult>();

  try {
    if (!protocols || protocols.length === 0) {
      return Promise.reject(
        new Error("At least one protocol is required for calibration")
      );
    }

    for (const [index, protocol] of protocols.entries()) {
      if (protocols.length > 1) {
        errorHint(`Calibrating (${index + 1}/${protocols.length})`);
      } else {
        errorHint("Calibrating");
      }

      // eslint-disable-next-line no-await-in-loop
      await setParameters(address, DEFAULT_TIMEOUT_MS, errorHint, protocol);

      LogService.log(`Done set parameter for normal measurement`);
      // eslint-disable-next-line no-await-in-loop
      const { value } = await measure(
        address,
        MEASUREMENT_TIMEOUT_MS,
        errorHint
      );

      // No dark value measurement for calibration, to follow protocol
      results.push({ value, darkValue: null });
    }
  } catch (e) {
    LogService.error(e);
  } finally {
    // TODO handle disconnect so we have no issues with errors
    await disconnect(address);
  }

  return results;
}

async function takeMeasurement(
  address: string,
  protocol: Protocol,
  errorHint: (error: string) => void
): Promise<MeasurementResult> {
  try {
    await connect(address, DEFAULT_TIMEOUT_MS, errorHint);

    // Parallelization of await in loop is not possible for the calibrations, they have be to done sequentially.
    // Measure dark value separately for backend's needed invalidity check
    // Please contact backend if you don't understand this. Don't touch
    // eslint-disable-next-line no-await-in-loop
    await setParameters(address, DEFAULT_TIMEOUT_MS, errorHint, {
      ...protocol,
      lightSourceAutomaticMode: false,
      lightSourceIntensity: 0,
      subtractDark: false,
    });

    LogService.log(`Done set parameter for dark measurement`);
    /* Workaround for darkValue limitation in firmware, see above */
    // eslint-disable-next-line no-await-in-loop
    const { value: darkValue } = await measure(
      address,
      MEASUREMENT_TIMEOUT_MS,
      errorHint
    );

    await setParameters(address, DEFAULT_TIMEOUT_MS, errorHint, protocol);
    const { value } = await measure(address, MEASUREMENT_TIMEOUT_MS, errorHint);
    return { value, darkValue };
  } finally {
    // TODO handle disconnect so we have no issues with errors
    await disconnect(address);
  }
}

const getDeviceInfo = async (
  address: string
): Promise<DeviceInfo | undefined> => {
  try {
    const connection = await Promise.race([
      BluetoothService.createConnection(address),
      timeout(DEFAULT_TIMEOUT_MS),
    ]);

    if (connection instanceof TimeoutError) {
      throw connection;
    }

    if (connection === null) {
      throw new Error("cannot create a connection for fetch device info");
    }

    const result = await BluetoothService.readDeviceInfo(address);
    return result;
  } finally {
    await disconnect(address);
  }
};

const DeviceCommunicationService = {
  calibrateDevice,
  takeMeasurement,
  getDeviceInfo,
};

export default DeviceCommunicationService;
