import React from "react";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import TableBody from "@mui/material/TableBody";
import { Model, ModelStatuses, formatToDateOnly } from "lib-core";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Grid from "@mui/material/Grid";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { CircularProgress, Button } from "@mui/material";
import LibraryTableHeaders from "../components/LibraryTable/LibraryTableHeaders";
import {
  MODEL_TRAINING_SUBHEADERS,
  ProductVariantTrainingModelFetchState,
} from "./productVariantTraining-hook";
import LibraryTableRows from "../components/LibraryTable/LibraryTableRows";
import { trainingHeaders } from "./helpers";

type Props = {
  state: ProductVariantTrainingModelFetchState["state"];
  onTriggerFetchSubheader: (subheaderType: MODEL_TRAINING_SUBHEADERS) => void;
  onTriggerCloseSubheader: (subheaderType: MODEL_TRAINING_SUBHEADERS) => void;
  createActionButtons: (
    model: Model,
    type: MODEL_TRAINING_SUBHEADERS
  ) => JSX.Element | null;
};

const subheaders = [
  [MODEL_TRAINING_SUBHEADERS.NEW, "New models"],
  [MODEL_TRAINING_SUBHEADERS.APPROVED, "Approved"],
  [MODEL_TRAINING_SUBHEADERS.DECOMMISSIONED, "Decommissioned"],
  [MODEL_TRAINING_SUBHEADERS.FAILED, "Failed trainings"],
] as const;

// Mapping for more user friendly format of status
const statusMapping: Record<ModelStatuses, string> = {
  [ModelStatuses.IN_TRAINING]: "In training",
  [ModelStatuses.WAITING_APPROVAL]: "Waiting approval",
  [ModelStatuses.APPROVED_PRODUCTION]: "Approved, production",
  [ModelStatuses.NOT_APPROVED]: "Not approved",
  [ModelStatuses.APPROVED_CANDIDATE]: "Approved, candidate",
  [ModelStatuses.DECOMMISSIONED]: "Decommissioned",
  [ModelStatuses.REJECTED]: "Rejected",
  [ModelStatuses.ERROR]: "Failed",
};

const BlendedSnapshotText = styled(Typography)`
  color: ${({ theme }) => theme.palette.brandGray.dark};
`;

const CustomTableCell = styled(TableCell)(
  ({ theme }) => `
    &.subheader {
      background: ${theme.palette.brandGray.light};
      .label {
        color: ${theme.palette.text.primary};
        font-size: ${theme.typography.h4.fontSize};
      }
      border-bottom-color: ${theme.palette.brandGray.main};
      line-height: 1;
      transition: color 0.15s;

      &.loading,
      &.empty {
        .label {
          color: ${theme.palette.brandGray.dark};
        }
      }
    }
  `
);

const LibraryTrainingTableSubheader: (props: {
  label: string;
  colSpan: number;
  loading: boolean;
  empty: boolean;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}) => JSX.Element = ({
  label,
  loading,
  empty,
  colSpan,
  onOpen,
  onClose,
  isOpen,
}) => {
  return (
    <TableHead>
      <TableRow>
        <CustomTableCell
          colSpan={colSpan}
          className={`
              subheader
              ${loading ? "loading" : ""}
              ${empty ? "empty" : ""}
            `}
        >
          <Grid
            container
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs="auto">
              <span className="label">{label}</span>
            </Grid>
            <Grid item alignItems="center">
              {loading && (
                <Typography variant="body1" color="primary">
                  Loading <CircularProgress size={16} />
                </Typography>
              )}
              {!loading && (
                <Button
                  variant="text"
                  size="small"
                  onClick={() => {
                    if (isOpen) {
                      onClose();
                    } else {
                      onOpen();
                    }
                  }}
                >
                  {isOpen ? (
                    <>
                      <>
                        Hide models{" "}
                        <KeyboardArrowUpIcon
                          style={{ verticalAlign: "baseline" }}
                        />
                      </>
                    </>
                  ) : (
                    <>
                      Show models{" "}
                      <KeyboardArrowDownIcon
                        style={{ verticalAlign: "baseline" }}
                      />
                    </>
                  )}
                </Button>
              )}
            </Grid>
          </Grid>
        </CustomTableCell>
      </TableRow>
    </TableHead>
  );
};

export const LibraryTrainingTable: (props: Props) => JSX.Element = ({
  state,
  onTriggerFetchSubheader,
  onTriggerCloseSubheader,
  createActionButtons,
}) => {
  // Map all model metrics into one object
  const metricHeaders = state.NEW.models
    .concat(state.APPROVED.models)
    .map((model) => {
      if (model.modelMetrics) {
        return Object.keys(model.modelMetrics);
      }
      return [];
    })
    .flat();

  // Map the object into key-label pairs (headers)
  const extraHeaders = [...new Set(metricHeaders)].map((key) => {
    return {
      key,
      label: key,
    };
  });

  // Join the original headers and the extra headers
  const joinedHeaders = trainingHeaders.concat(extraHeaders);

  return (
    <TableContainer component={Paper} elevation={0} square>
      <Table id="library-training-table">
        <LibraryTableHeaders headers={joinedHeaders} />
        {subheaders.map(([subheaderType, label]) => {
          const isSubheaderLoading = state[subheaderType].loading;
          const isSubheaderOpen = state[subheaderType].isOpen;
          const rows = state[subheaderType].models.map((model) => {
            let blendedSnapshotText = "";
            if (model.blendedSnapshot) {
              blendedSnapshotText = `
            Blended: ${formatToDateOnly(model.blendedSnapshot.createdOn)}, V${
                model.blendedSnapshot.version
              }.0
            `;
            }
            return {
              ...model,
              ...model.modelMetrics,
              modelStatus: statusMapping[model.modelStatus], // Map status to more user friendly
              snapshots: (
                <>
                  <Typography variant="body2">{`Product Variant: ${formatToDateOnly(
                    model.modelSnapshot.createdOn
                  )}, V${model.modelSnapshot.version}.0`}</Typography>
                  <BlendedSnapshotText variant="body2" color="brandGray">
                    {blendedSnapshotText}
                  </BlendedSnapshotText>
                </>
              ), // Show snapshot date instead of object
              actionButtons: createActionButtons(model, subheaderType),
            };
          });
          const isSubheaderEmpty = state[subheaderType].models.length === 0;

          const subheaderLabel = (() => {
            if (isSubheaderLoading) {
              return `${label} (...)`;
            }
            if (!state[subheaderType].isOpen) {
              return label;
            }
            return `${label} (${rows.length})`;
          })();
          return (
            <React.Fragment key={subheaderType}>
              <LibraryTrainingTableSubheader
                label={subheaderLabel}
                colSpan={joinedHeaders.length}
                loading={isSubheaderLoading}
                empty={isSubheaderEmpty}
                isOpen={state[subheaderType].isOpen}
                onOpen={() => onTriggerFetchSubheader(subheaderType)}
                onClose={() => onTriggerCloseSubheader(subheaderType)}
              />
              <TableBody className={isSubheaderLoading ? "loading" : ""}>
                <LibraryTableRows
                  rows={isSubheaderOpen ? rows : []}
                  headers={joinedHeaders}
                />
              </TableBody>
            </React.Fragment>
          );
        })}
      </Table>
    </TableContainer>
  );
};
