import React from "react";
import styled from "styled-components";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import SortingArrowDown from "@mui/icons-material/ArrowDropDown";
import SortingArrowUp from "@mui/icons-material/ArrowDropUp";

import { Sorting } from "../../types/types-api";
import { DataTableHeaderType } from "./datatable-types";

interface ListTableHeadersProps {
  headers: DataTableHeaderType[];
  sorting: Sorting[];
  onSort(key: string): void;
}

const HeaderValue = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

const ListTableHeaders: (props: ListTableHeadersProps) => JSX.Element = ({
  headers,
  sorting,
  onSort,
}) => {
  const getSortInformation = (key: string) => {
    const sort = sorting.find((sort) => sort.key === key);
    if (sort) {
      return sort.direction;
    }
    return false;
  };
  const getSortInformationIcon = (key: string) => {
    const sort = getSortInformation(key);
    if (!sort) {
      return null;
    }
    return sort === "asc" ? <SortingArrowDown /> : <SortingArrowUp />;
  };

  return (
    <TableHead>
      <TableRow>
        {headers.map((header, idx) => (
          <TableCell
            key={idx}
            sortDirection={getSortInformation(header.key)}
            onClick={() => {
              if (header.disableSorting) {
                return;
              }
              onSort(header.key);
            }}
          >
            <HeaderValue>
              {header.label}
              {getSortInformationIcon(header.key)}
            </HeaderValue>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default ListTableHeaders;
