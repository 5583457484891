import React from "react";
import styled from "styled-components";
import { Route } from "react-router-dom";
import { AppRoute } from "./router-types";

interface ViewProps {
  path: string;
  route: AppRoute;
  exact: boolean;
  setSelected?: (selected: string) => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
`;

const View: (props: ViewProps) => JSX.Element = ({ path, route, exact }) => {
  const { home, routes = [] } = route;

  return (
    <Container>
      <Route
        key={`${home.name}_${home.path}`}
        exact={exact}
        path={`${path}/`}
        component={home.component}
      />
      {routes.map((subRoute) => (
        <Route
          key={subRoute.name}
          path={`${path}${subRoute.path}`}
          component={subRoute.component}
        />
      ))}
    </Container>
  );
};

export default View;
