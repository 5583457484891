const supportedFileTypes = ["text/plain", "text/csv"];

export const countFileLines = async (file: File) => {
  // only support csv text files
  if (supportedFileTypes.includes(file.type) && file.name.endsWith(".csv")) {
    const content = await file.text();
    return content.split("\n").filter((line) => !!line.trim()).length;
  }
  throw new Error(`Non supported file type ${file.type}`);
};
