interface Resource {
  objectId: ObjectIds;
  service: Services;
  type: Types;
}

export interface PermissionKey extends Resource {
  operation: Operations;
}

export interface Permission {
  createdOn: string;
  operation: Operations;
  permissionId: number;
  resource: Resource;
  roleId: number;
  superAdmin: boolean;
}

export enum WildcardOptions {
  All = "*",
}

export enum CrudOperation {
  Create = "create",
  Delete = "delete",
  Read = "read",
  Write = "write",
}

export enum ResourceService {
  AccessControl = "access-control",
  Authentifield = "authentifield",
  Device = "device",
  Experiment = "experiment",
  History = "history",
  ModelHistory = "model-history",
  View = "view",
}

export enum ResourceType {
  DataSet = "data-set",
  Device = "device",
  DeviceUpdate = "device-update",
  DrugAuthentication = "drug-authentication",
  DummyProductVariant = "dummy-product-variant",
  Experiment = "experiment",
  Model = "model",
  Modeling = "modelling",
  ModellingApproval = "modelling-approval",
  ProductLibrary = "product-library",
  ProductLibraryPlanningApproval = "product-library-planning-approval",
  ProductLibraryPlanningRequest = "product-library-planning-request",
  Protocol = "protocol",
  Resource = "resource",
  ResultMitigation = "result-mitigation",
  Role = "role",
  Snapshot = "snapshots",
  BlendedSnapshot = "blended-snapshots",
  Spectra = "spectra",
  User = "user",
  UserCollection = "user-collection",
  View = "view",
  AuditTrail = "audit-trail",
  ProductLibraryPlanning = "product-library-planning",
  ProductLibraryTraining = "product-library-training",
  ProductLibraryModels = "product-library-models",
  WebPortal = "web-portal",
  MobileApp = "mobile-app",
  Labscanner = "labscanner",
  Results = "results",
  DeviceList = "device-list",
}

export type ObjectIds = WildcardOptions;

export type Operations = WildcardOptions | CrudOperation;

export type Types = WildcardOptions | ResourceType;

export type Services = WildcardOptions | ResourceService;
