import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  ImportModel,
  PermissionKeys,
  PermissionRequired,
  Model,
  ModelStatuses,
  ModelApprovalData,
  LogService,
  ProductVariantSnapshot,
  usePermissionChecking,
  ModelLogsDownloadRequest,
  useNotification,
  NotificationTypes,
  ProductVariant,
} from "lib-core";
import { Button } from "@mui/material";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import ModalApprove from "./ModalApprove";

import useProductVariantTraining, {
  MODEL_TRAINING_SUBHEADERS,
  useProductVariantTrainingModels,
} from "./productVariantTraining-hook";
import LibrarySubheader from "./LibrarySubheader";
import { useBlendedSnapshot } from "./blendedSnapshot-hook";
import { LibraryTrainingTable } from "./LibraryTrainingTable";

interface TrainingTabProps {
  productVariant: ProductVariant;
}

const ActionButtons = styled.nav`
  display: flex;
  justify-content: flex-start;
  gap: 15px;
`;

const CustomIconButton = styled(Button)`
  min-width: 0px;
  padding: 5px;
`;

const { readModelling, writeModelling, createModelling, readBlendedSnapshot } =
  PermissionKeys;

const TrainingTab: (props: TrainingTabProps) => JSX.Element = ({
  productVariant,
}) => {
  const [isModifyingTraining, setIsModifyingTraining] = useState(false);
  const { usableSnapshots: blendedSnapshots = [] } = useBlendedSnapshot();
  const {
    cancelModelTrainingRun,
    loadingPlatforms,
    changeLoading,
    approveModel,
    getProductVariantSnapshots,
    snapshots,
    downloadModelTrainingLogs,
    rejectModel,
    fetchPlatforms,
    platforms,
  } = useProductVariantTraining();

  const { state, fetchTrainingModels, closeSubHeader } =
    useProductVariantTrainingModels(productVariant.id);

  const approvedModels = state[MODEL_TRAINING_SUBHEADERS.APPROVED].models;

  const [downloadingRunId, setDownloadingRunId] = useState<
    string | undefined
  >();
  const requirePermission = usePermissionChecking();
  const hasModelPlatformPermissions = requirePermission([createModelling]);

  // Fetch product variant information by id and fecth models for list
  const fetchTrainingTabData = async () => {
    await Promise.allSettled([
      fetchTrainingModels(MODEL_TRAINING_SUBHEADERS.NEW),
      fetchTrainingModels(MODEL_TRAINING_SUBHEADERS.APPROVED),
      ...(hasModelPlatformPermissions
        ? [getProductVariantSnapshots(productVariant.id), fetchPlatforms()]
        : []),
    ]);
  };

  const [modal, setModal] = useState(false);
  const [modelName, setName] = useState<string>();
  const [modalState, setModalState] = useState<"approve" | "reject">("approve");
  const [currentApprovalData, setCurrentApprovalData] =
    useState<ModelApprovalData>();

  const { createNotification } = useNotification();

  const onModalClose = () => {
    setModal(false);
    setName("");
  };

  const onModalOpen = (
    modelVersionId: number,
    modelExternalName: string,
    modelName: string,
    state: "approve" | "reject"
  ) => {
    setCurrentApprovalData({ modelVersionId, modelName });
    setModal(true);
    setName(`${modelExternalName} (version ${modelVersionId})`);
    setModalState(state);
  };

  // Usable snapshots to start trainings
  const releasedSnapshots =
    productVariant?.metadata.snapshotApprovalStatus.released ?? [];

  const filteredSnapshots = snapshots.filter((obj: ProductVariantSnapshot) => {
    return releasedSnapshots.includes(obj.id);
  });

  const findPreviousCandidate = () => {
    const approved = approvedModels.find(
      (model) => model.modelStatus === ModelStatuses.APPROVED_CANDIDATE
    );
    if (approved) {
      return `${approved?.modelExternalName} (version ${approved?.modelVersionId})`;
    }
    return null;
  };

  useEffect(() => {
    fetchTrainingTabData();
  }, [productVariant]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCancelingTraining = (viewId: number) => {
    cancelModelTrainingRun(viewId)
      .then(() => {
        fetchTrainingTabData();
      })
      .catch((err) => {
        LogService.error(err);
      });
  };

  const handleApprovingModel = (modelApprovalData: ModelApprovalData) => {
    setModal(false);
    setIsModifyingTraining(true);
    approveModel(modelApprovalData)
      .then(() => {
        fetchTrainingTabData();

        setCurrentApprovalData({ modelVersionId: 0, modelName: "" });
      })
      .catch((err) => {
        LogService.error(err);
      })
      .finally(() => setIsModifyingTraining(false));
  };

  const handleRejectingModel = (modelApprovalData: ModelApprovalData) => {
    setModal(false);
    setIsModifyingTraining(true);
    rejectModel(modelApprovalData)
      .then(() => {
        fetchTrainingTabData();

        setCurrentApprovalData({ modelVersionId: 0, modelName: "" });
      })
      .catch((err) => {
        LogService.error(err);
      })
      .finally(() => setIsModifyingTraining(false));
  };

  const onClickDownloadModelTrainingLogs = (
    request: ModelLogsDownloadRequest
  ) => {
    setDownloadingRunId(request.runId);
    downloadModelTrainingLogs(request)
      .catch(() => {
        createNotification({
          type: NotificationTypes.Snackbar,
          severity: "error",
          message: `Failed to download the log file, please try again`,
        });
      })
      .finally(() => {
        setDownloadingRunId(undefined);
      });
  };

  const downloadLogButton = ({ modelName, runId, logsAvailable }: Model) => {
    return (
      <PermissionRequired
        key="download-logs"
        hidden
        permissionKeys={[readBlendedSnapshot]}
      >
        <CustomIconButton
          disabled={!!downloadingRunId || !logsAvailable}
          variant="outlined"
          onClick={() => {
            onClickDownloadModelTrainingLogs({ modelName, runId });
          }}
        >
          <DescriptionOutlinedIcon />
        </CustomIconButton>
      </PermissionRequired>
    );
  };

  const createActionButtons = (model: Model) => {
    const {
      modelStatus,
      viewId,
      modelVersionId,
      modelExternalName,
      modelName,
    } = model;
    let buttons: JSX.Element[] = [downloadLogButton(model)];

    if (modelStatus === ModelStatuses.WAITING_APPROVAL) {
      buttons = buttons.concat(
        <PermissionRequired
          key="approve"
          hidden
          permissionKeys={[readModelling, writeModelling]}
        >
          <Button
            onClick={() =>
              onModalOpen(
                modelVersionId,
                modelExternalName,
                modelName,
                "approve"
              )
            }
            size="small"
          >
            Approve
          </Button>
        </PermissionRequired>,
        <PermissionRequired
          key="reject"
          hidden
          permissionKeys={[readModelling, writeModelling]}
        >
          <Button
            onClick={() =>
              onModalOpen(
                modelVersionId,
                modelExternalName,
                modelName,
                "reject"
              )
            }
            variant="outlined"
            size="small"
          >
            Reject
          </Button>
        </PermissionRequired>
      );
    }
    if (modelStatus === ModelStatuses.IN_TRAINING) {
      buttons = buttons.concat(
        <PermissionRequired
          hidden
          permissionKeys={[readModelling, writeModelling]}
        >
          <Button
            onClick={() => fetchTrainingTabData()}
            disabled={changeLoading}
            variant="contained"
            size="small"
          >
            Refresh
          </Button>
        </PermissionRequired>,
        <PermissionRequired
          key="reject"
          hidden
          permissionKeys={[readModelling, writeModelling]}
        >
          <Button
            onClick={() => handleCancelingTraining(viewId)}
            disabled={changeLoading}
            variant="outlined"
            size="small"
          >
            Cancel
          </Button>
        </PermissionRequired>
      );
    }

    if (buttons.length === 0) return null;
    return <ActionButtons>{buttons}</ActionButtons>;
  };

  const onTriggerFetchSubheader = (
    subHeaderType: MODEL_TRAINING_SUBHEADERS
  ) => {
    return fetchTrainingModels(subHeaderType);
  };

  const onTriggerCloseSubheader = (
    subHeaderType: MODEL_TRAINING_SUBHEADERS
  ) => {
    closeSubHeader(subHeaderType);
  };

  const isAnythingLoading =
    state.NEW.loading ||
    state.APPROVED.loading ||
    loadingPlatforms ||
    isModifyingTraining;

  return (
    <>
      <LibrarySubheader
        loading={isAnythingLoading}
        loadingTitle="Loading model packages..."
        title="Model packages loaded"
        buttons={
          <PermissionRequired hidden permissionKeys={[createModelling]}>
            <ImportModel
              disabled={isAnythingLoading}
              productVariantSnapshots={filteredSnapshots}
              blendedSnapshots={blendedSnapshots}
              onSuccess={fetchTrainingTabData}
              platforms={platforms}
            />
          </PermissionRequired>
        }
      />

      <ModalApprove
        state={modalState}
        open={modal}
        onClose={onModalClose}
        onSubmit={() =>
          currentApprovalData && modalState === "approve"
            ? handleApprovingModel(currentApprovalData)
            : currentApprovalData && handleRejectingModel(currentApprovalData)
        }
        newCandidateName={modelName || "New candidate"}
        oldCandidateName={findPreviousCandidate()}
      />

      <LibraryTrainingTable
        state={state}
        createActionButtons={createActionButtons}
        onTriggerFetchSubheader={onTriggerFetchSubheader}
        onTriggerCloseSubheader={onTriggerCloseSubheader}
      />
    </>
  );
};

export default TrainingTab;
