import React, { useEffect, useState } from "react";
import styled from "styled-components";

import ImageService from "../../services/domain/ImageService";
import { Image } from "../../types/contracts/MeasurementHistoryItem";
import SimpleMobileImagePreview from "./SimpleMobileImagePreview";

interface PhotolistProps {
  measurementId: string;
  photoList: Image[];
  userId: string;
}

type PhotoListArray = {
  blob: Blob;
  key: string;
}[];

const ImageList = styled.div`
  text-align: left;
  white-space: nowrap;
  width: 100vw;
  height: 70px;
  overflow-y: hidden;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[400]};
`;

const SmallImage = styled.img`
  display: inline-block;
  overflow: hidden;
  height: 100%;
`;

const Photolist: (props: PhotolistProps) => JSX.Element = ({
  measurementId,
  photoList: photoIds,
  userId,
}) => {
  const [photoList, setPhotoList] = useState<PhotoListArray>([]);
  const [selectedImage, setSelectedImage] = React.useState<Blob>();

  const handleOpen = (selectedImage: Blob) => {
    setSelectedImage(selectedImage);
  };

  const handleClose = () => {
    setSelectedImage(undefined);
  };

  useEffect(() => {
    const getPicturesAsBlob = async () => {
      const results = await Promise.all(
        photoIds.map(async (photo) => {
          const photoId = photo.id.toString();
          const key = `${measurementId}-${photoId}`;

          const blob = await ImageService.getMeasurementImage(
            userId,
            measurementId,
            photoId
          );

          return { blob, key };
        })
      );

      setPhotoList(results);
    };

    getPicturesAsBlob();
  }, [measurementId, photoIds, userId]);

  if (photoList.length < 1) {
    return <></>;
  }

  return (
    <>
      <SimpleMobileImagePreview
        handleClose={handleClose}
        image={selectedImage}
        open={!!selectedImage}
      />
      <ImageList>
        {photoList.map((photo) => {
          const { blob, key } = photo;
          const url = URL.createObjectURL(blob);

          return (
            <SmallImage key={key} onClick={() => handleOpen(blob)} src={url} />
          );
        })}
      </ImageList>
    </>
  );
};

export default Photolist;
