import { useCallback, useEffect, useReducer, useState } from "react";
import {
  ProductService,
  Model,
  useError,
  ModelApprovalData,
  ProductVariantSnapshot,
  useHttpErrorReader,
  ModelLogsDownloadRequest,
  ModelTrainingStatus,
  ModelStatuses,
} from "lib-core";
import { AxiosError } from "axios";
import { downloadFile } from "../shared/file-helpers";

type ProductVariantTrainingData = {
  cancelModelTrainingRun(viewId: number): Promise<void>;
  approveModel(modelApprovalData: ModelApprovalData): Promise<void>;
  rejectModel(modelApprovalData: ModelApprovalData): Promise<void>;
  decommissionModel(modelApprovalData: ModelApprovalData): Promise<void>;
  fetchPlatforms(): Promise<void>;

  loadingPlatforms: boolean;

  changeLoading: boolean;
  error: unknown | undefined;
  getProductVariantSnapshots(variantId: number): Promise<void>;
  downloadModelTrainingLogs(request: ModelLogsDownloadRequest): Promise<void>;
  snapshots: ProductVariantSnapshot[];
  platforms: string[];
};

const useProductVariantTraining = (): ProductVariantTrainingData => {
  const [loadingPlatforms, setLoadingPlatforms] = useState<boolean>(false);

  const [changeLoading, setChangeLoading] = useState<boolean>(false);
  const [error, setError] = useState<unknown>();
  const { handleErrors } = useError();
  const errorReader = useHttpErrorReader();
  const [snapshots, setSnapshots] = useState<ProductVariantSnapshot[]>([]);
  const [platforms, setPlatforms] = useState<string[]>([]);

  const cancelModelTrainingRun = async (viewId: number) => {
    setChangeLoading(true);
    setError(undefined);
    try {
      await ProductService.cancelModelTrainingRun(viewId);
      setChangeLoading(false);
    } catch (err) {
      const error = err as AxiosError;

      if (error.response) {
        handleErrors({
          error,
          customHandling: errorReader(error.response),
        });
      }
      setError(err);
    }
    setChangeLoading(false);
    setError(false);
  };

  const getProductVariantSnapshots = async (productVariantId: number) => {
    setChangeLoading(true);
    setError(undefined);
    try {
      const result = await ProductService.getProductVariantSnapshots(
        productVariantId
      );
      setSnapshots(result);
      setChangeLoading(false);
    } catch (err) {
      const { response: errorResponse } = err as AxiosError;

      if (errorResponse) {
        const { data: error } = errorResponse;
        handleErrors({
          error,
          customHandling: errorReader(errorResponse),
        });
      }
      setError(err);
    }
    setChangeLoading(false);
    setError(false);
  };

  const approveModel = async (modelApprovalData: ModelApprovalData) => {
    setChangeLoading(true);
    setError(undefined);
    try {
      await ProductService.approveModel(modelApprovalData);

      setChangeLoading(false);
    } catch (err) {
      const { response: errorResponse } = err as AxiosError;

      if (errorResponse) {
        const { data: error } = errorResponse;
        handleErrors({
          error,
          customHandling: errorReader(errorResponse),
        });
      }
      setError(err);
    }
    setChangeLoading(false);
    setError(false);
  };

  const rejectModel = async (modelApprovalData: ModelApprovalData) => {
    setChangeLoading(true);
    setError(undefined);
    try {
      await ProductService.rejectModel(modelApprovalData);

      setChangeLoading(false);
    } catch (err) {
      const { response: errorResponse } = err as AxiosError;

      if (errorResponse) {
        const { data: error } = errorResponse;
        handleErrors({
          error,
          customHandling: errorReader(errorResponse),
        });
      }
      setError(err);
    }
    setChangeLoading(false);
    setError(false);
  };

  const decommissionModel = async (modelApprovalData: ModelApprovalData) => {
    setChangeLoading(true);
    setError(undefined);
    try {
      await ProductService.decommissionModel(modelApprovalData);

      setChangeLoading(false);
    } catch (err) {
      const { response: errorResponse } = err as AxiosError;

      if (errorResponse) {
        const { data: error } = errorResponse;
        handleErrors({
          error,
          customHandling: errorReader(errorResponse),
        });
      }
      setError(err);
    }
    setChangeLoading(false);
    setError(false);
  };

  const fetchPlatforms = async () => {
    setLoadingPlatforms(true);
    setError(undefined);
    try {
      const response = await ProductService.getModelPlatforms();

      setPlatforms(response);
    } catch (err) {
      const { response: errorResponse } = err as AxiosError;

      if (errorResponse) {
        const { data: error } = errorResponse;
        handleErrors({
          error,
          customHandling: errorReader(errorResponse),
        });
      }
      setError(err);
    }
    setLoadingPlatforms(false);
  };

  const downloadModelTrainingLogs = useCallback(
    async (request: ModelLogsDownloadRequest) => {
      const logContent = await ProductService.downloadModelTrainingLogs(
        request
      );
      const url = `data:text/plain;charset=utf-8,${encodeURI(logContent)}`;
      // It is not possible to follow the 302 redirect s3 url to get the file name,
      // hence we need to manually construct the file name in the FE
      const fileName = `model_training_logs_${request.modelName}_${request.runId}.log`;
      downloadFile(fileName, url);
    },
    []
  );

  return {
    cancelModelTrainingRun,
    loadingPlatforms,
    changeLoading,
    error,
    approveModel,
    getProductVariantSnapshots,
    downloadModelTrainingLogs,
    snapshots,
    rejectModel,
    decommissionModel,
    fetchPlatforms,
    platforms,
  };
};

export enum MODEL_TRAINING_SUBHEADERS {
  NEW = "NEW",
  APPROVED = "APPROVED",
  DECOMMISSIONED = "DECOMMISSIONED",
  FAILED = "FAILED",
}

type QueryForSubheader = Parameters<
  typeof ProductService.getProductVariantTrainings
>[1];

const getModelQueryForSubheader = (
  subheader: MODEL_TRAINING_SUBHEADERS
): QueryForSubheader[] => {
  switch (subheader) {
    case MODEL_TRAINING_SUBHEADERS.NEW:
      return [
        // ModelStatuses.IN_TRAINING
        {
          modelTrainingStatus: [ModelTrainingStatus.TRAINING],
        },
        // ModelStatuses.WAITING_APPROVAL
        // Missing this check from previous logic: model.pendingStatus === null
        {
          modelStatus: [ModelStatuses.NOT_APPROVED],
          modelTrainingStatus: [ModelTrainingStatus.DONE],
        },
      ];
    case MODEL_TRAINING_SUBHEADERS.APPROVED:
      return [
        {
          modelStatus: [
            ModelStatuses.APPROVED_PRODUCTION,
            ModelStatuses.APPROVED_CANDIDATE,
          ],
        },
      ];
    case MODEL_TRAINING_SUBHEADERS.FAILED:
      return [
        {
          modelTrainingStatus: [ModelTrainingStatus.ERROR],
        },
      ];
    case MODEL_TRAINING_SUBHEADERS.DECOMMISSIONED:
      return [
        {
          modelStatus: [ModelStatuses.DECOMMISSIONED],
        },
      ];
    default:
      return [];
  }
};

type ModelTrainingState = Record<
  MODEL_TRAINING_SUBHEADERS,
  { loading: boolean; models: Model[]; isOpen: boolean }
>;

const initialModelTrainingState: ModelTrainingState = Object.freeze({
  [MODEL_TRAINING_SUBHEADERS.NEW]: {
    loading: true,
    isOpen: true,
    models: [] as Model[],
  },
  [MODEL_TRAINING_SUBHEADERS.APPROVED]: {
    loading: true,
    isOpen: true,
    models: [] as Model[],
  },
  [MODEL_TRAINING_SUBHEADERS.DECOMMISSIONED]: {
    loading: false,
    isOpen: false,
    models: [] as Model[],
  },
  [MODEL_TRAINING_SUBHEADERS.FAILED]: {
    loading: false,
    isOpen: false,
    models: [] as Model[],
  },
});

const modelTrainingLoadingStateReducer = (
  prevState: ModelTrainingState,
  action:
    | {
        subheader: MODEL_TRAINING_SUBHEADERS;
        loading: boolean;
        type: "loading";
      }
    | {
        subheader: MODEL_TRAINING_SUBHEADERS;
        models: Model[];
        type: "models";
      }
    | { subheader: MODEL_TRAINING_SUBHEADERS; type: "close" }
    | { type: "reset" }
) => {
  const newState = { ...prevState };

  if (action.type === "reset") {
    return initialModelTrainingState;
  }

  newState[action.subheader] = { ...prevState[action.subheader] };
  if (action.type === "loading") {
    newState[action.subheader].loading = action.loading;
    if (action.loading) {
      newState[action.subheader].isOpen = true;
    }
  }
  if (action.type === "models") {
    newState[action.subheader].models = action.models;
    newState[action.subheader].loading = false;
    newState[action.subheader].isOpen = true;
  }
  if (action.type === "close") {
    newState[action.subheader].isOpen = false;
    newState[action.subheader].loading = false;
  }
  return newState;
};

export type ProductVariantTrainingModelFetchState = {
  state: ModelTrainingState;
  fetchTrainingModels: (
    modelSubheader: MODEL_TRAINING_SUBHEADERS
  ) => Promise<void>;
  closeSubHeader: (type: MODEL_TRAINING_SUBHEADERS) => void;
};

// Model status is not quite straigthforward and needs to be resolved.
const resolveModelStatus = (model: Model) => {
  // If model is in training
  if (model.modelTrainingStatus === ModelTrainingStatus.TRAINING) {
    return ModelStatuses.IN_TRAINING;
  }

  // If model is failed
  if (model.modelTrainingStatus === ModelTrainingStatus.ERROR) {
    return ModelStatuses.ERROR;
  }

  // If model is in approval status
  if (
    model.modelTrainingStatus === ModelTrainingStatus.DONE &&
    ModelStatuses.NOT_APPROVED === model.modelStatus &&
    model.pendingStatus === null
  ) {
    return ModelStatuses.WAITING_APPROVAL;
  }

  // Approved and decommissioned statuses are clear so these can be returned as is
  return model.modelStatus;
};

export const useProductVariantTrainingModels: (
  variantId: number
) => ProductVariantTrainingModelFetchState = (variantId) => {
  const [state, dispatch] = useReducer(
    modelTrainingLoadingStateReducer,
    null,
    () => JSON.parse(JSON.stringify(initialModelTrainingState)) // deepclone the initial object
  );

  useEffect(() => {
    return () => {
      dispatch({ type: "reset" });
    };
  }, [variantId]);

  const { handleErrors } = useError();
  const errorReader = useHttpErrorReader();

  const fetchTrainingModels = async (
    modelSubheader: MODEL_TRAINING_SUBHEADERS
  ) => {
    dispatch({
      subheader: modelSubheader,
      type: "loading",
      loading: true,
    });
    try {
      const queries = getModelQueryForSubheader(modelSubheader);
      const fetches = queries.map((query) =>
        ProductService.getProductVariantTrainings(variantId, query)
      );
      const responses = await Promise.all(fetches);

      dispatch({
        subheader: modelSubheader,
        type: "models",
        models: responses
          .flatMap((response) => response.rows)
          .map((model) => ({
            ...model,
            modelStatus: resolveModelStatus(model),
          })),
      });
    } catch (err) {
      const { response: errorResponse } = err as AxiosError;

      if (errorResponse) {
        const { data: error } = errorResponse;
        handleErrors({
          error,
          customHandling: errorReader(errorResponse),
        });
      }
    }
    dispatch({
      subheader: modelSubheader,
      loading: false,
      type: "loading",
    });
  };

  const closeSubHeader = (type: MODEL_TRAINING_SUBHEADERS) => {
    dispatch({ type: "close", subheader: type });
  };

  return {
    fetchTrainingModels,
    state,
    closeSubHeader,
  };
};

export default useProductVariantTraining;
