import { useQuery } from "@tanstack/react-query";
import {
  ProductService,
  BlendedSnapshot,
  BlendedSnapshotUpcoming,
  Constants,
  usePermissionChecking,
  PermissionKeys,
} from "lib-core";
import { useCallback, useMemo } from "react";
import { downloadFile } from "../shared/file-helpers";

const { readBlendedSnapshot } = PermissionKeys;

type BlendedSnapshotData = {
  blendedSnapshots: BlendedSnapshot[] | undefined;
  usableSnapshots: BlendedSnapshot[] | undefined;
  isAllowed: boolean;
  isLastSnapshotFailed: boolean;
  latestUsableSnapshot: BlendedSnapshot | undefined;
  blendedSnapshotUpcoming: BlendedSnapshotUpcoming | undefined;
  downloadBlendedSnapshotMetadata: (
    id: string,
    version: number
  ) => Promise<void>;
  isFetchingBlendedSnapshots: boolean;
  isErrorFetchingBlendedSnapshots: boolean;
  isFetchingBlendedSnapshotUpcoming: boolean;
  isErrorBlendedSnapshotUpcoming: boolean;
};

export const useBlendedSnapshot = (): BlendedSnapshotData => {
  const requirePermission = usePermissionChecking();

  const havePermissionToViewAndSeeBlendedSnapshots = requirePermission([
    readBlendedSnapshot,
  ]);

  const {
    isFetching: isFetchingBlendedSnapshots,
    isError: isErrorFetchingBlendedSnapshots,
    data: blendedSnapshots,
  } = useQuery({
    queryKey: ["blended-snapshot-listing"],
    queryFn: () =>
      ProductService.listBlendedSnapshots().then((snapshots) => {
        return snapshots.sort((snapshotA, snapshotB) => {
          return (
            new Date(snapshotB.modifiedOn).getTime() -
            new Date(snapshotA.modifiedOn).getTime()
          );
        });
      }),
    enabled: havePermissionToViewAndSeeBlendedSnapshots,
  });

  const usableSnapshots = useMemo(() => {
    return (
      blendedSnapshots?.filter((snapshot) => snapshot.state === "done") ?? []
    );
  }, [blendedSnapshots]);

  const latestUsableSnapshot = useMemo(() => {
    const [result] = usableSnapshots;
    return result;
  }, [usableSnapshots]);

  const {
    isFetching: isFetchingBlendedSnapshotUpcoming,
    isError: isErrorBlendedSnapshotUpcoming,
    data: blendedSnapshotUpcoming,
  } = useQuery({
    queryKey: ["blended-snapshot-upcoming"],
    queryFn: ProductService.fetchBlendedSnapshotUpcomingTime,
    enabled: havePermissionToViewAndSeeBlendedSnapshots,
  });

  const downloadBlendedSnapshotMetadata = useCallback(
    async (id: string, version: number) => {
      const csv = await ProductService.downloadBlendedSnapshotMetadata(id);

      const url = `data:text/csv;charset=utf-8,${encodeURI(csv)}`;
      // It is not possible to follow the 302 redirect s3 url to get the file name,
      // hence we need to manually construct the file name in the FE
      // Spec is in https://dev.azure.com/SpectralEngines/SE%20SW/_sprints/taskboard/SE%20SW%20Team/SE%20SW/Sprint%2026?workitem=5975
      const fileName = `blended_snapshot_${Constants.defaultProtocol}_version_${version}.csv`;

      downloadFile(fileName, url);
    },
    []
  );

  const isLastSnapshotFailed =
    blendedSnapshots?.slice(-1)?.[0]?.state === "error";

  return {
    blendedSnapshots,
    isAllowed: havePermissionToViewAndSeeBlendedSnapshots,
    isLastSnapshotFailed,
    isFetchingBlendedSnapshots,
    isErrorFetchingBlendedSnapshots,
    isFetchingBlendedSnapshotUpcoming,
    isErrorBlendedSnapshotUpcoming,
    downloadBlendedSnapshotMetadata,
    blendedSnapshotUpcoming,
    usableSnapshots,
    latestUsableSnapshot,
  };
};
