/* eslint-disable import/no-cycle */
import { PermissionKeys } from "lib-core";
import { AppRoute } from "./router-types";

import HomeView from "../home/Home";
import DeviceHome from "../device/DeviceHome";
import HistoryHomeView from "../history/HistoryHome";
import LibraryHomeView from "../library/LibraryHome";
import ErrorPage403 from "../library/ErrorPage";

import sensorsBackground from "../components/images/home_sensors.jpg";
import measurementsBackground from "../components/images/home_measurements.jpg";
import libraryBackground from "../components/images/home_library.jpg";

// eslint-disable-next-line prettier/prettier
const {
  readMeasurementHistory,
  readProductLibrary,
  readSensorManagement,
  readWebPortal,
} = PermissionKeys;

const appViews: AppRoute[] = [
  {
    home: {
      component: HomeView,
      name: "Home",
      path: "/home",
      exact: true,
      requiredKeys: [readWebPortal],
    },
  },
  {
    home: {
      attributes: {
        altText: "Sensor management",
        description: "Manage NIR devices",
        image: sensorsBackground,
        title: "Sensors",
      },
      component: DeviceHome,
      name: "Sensors",
      path: "/devices",
      exact: true,
      requiredKeys: [readSensorManagement, readWebPortal],
    },
  },
  {
    home: {
      attributes: {
        altText: "Measurements",
        description: "Review and authenticate measurement history",
        image: measurementsBackground,
        title: "Measurements",
      },
      exact: false,
      component: HistoryHomeView,
      name: "Measurements",
      path: "/history",
      requiredKeys: [readMeasurementHistory, readWebPortal],
    },
  },
  {
    home: {
      attributes: {
        altText: "Library management",
        description: "Manage products, reference materials and models",
        image: libraryBackground,
        title: "Library",
      },
      exact: true,
      component: LibraryHomeView,
      name: "Library",
      path: "/library",
      requiredKeys: [readProductLibrary, readWebPortal],
    },
  },
  {
    home: {
      component: ErrorPage403,
      name: "Error-403",
      exact: true,
      path: "/error-403",
      requiredKeys: [],
    },
  },
];

export default appViews;
